import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyTrick = createAsyncThunk(
  'MyTrick/getMyTrick',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/mytrick', {trickStatus:params?.trickStatus ?? 1},{ withCredentials:true, headers })
      return {
        myTrick: response?.data?.data,
        total: response?.data?.data?.total,
        // noofPages: response?.data?.data?.noofPages,
        pageNumber: response?.data?.data?.pageNumber,
        pageSize: response?.data?.data?.pageSize,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setError(e?.response?.data?.message))
      dispatch(setLoader(false))
    }
  }
)

export const MyTrick = createSlice({
  name: 'MyTrick',
  initialState: {
    myTrick:[],
    loader: true,
    error:'',
    pageNumber: 1,
    pageSize: 12,
    Search: '',
    total: 0,
    selected: null
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMyTrick.fulfilled, (state, action) => {
      //console.log('action', action)
      state.myTrick = action?.payload?.myTrick
      state.pageNumber = action?.payload?.pageNumber
      state.pageSize = action?.payload?.pageSize
      state.total = action?.payload?.total
      state.loader = false
    })
   
  }
})

export const { setSelect,setError, setLoader, setPageNo, setPageSize, setSearch } =
  MyTrick.actions

export default MyTrick.reducer